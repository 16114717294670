import * as React from "react"
import { graphql } from "gatsby"
import Hero from "../components/templates/Hero"
import Intro from "../components/templates/Intro"
import Layout from "../components/global/Layout"
import Masonry from "../components/templates/Masonry"
import Blocks from "../components/templates/Blocks"
import HomeForm from "../components/templates/HomeForm"
import Seo from "../components/global/Seo"

export default function Home({ data }) {
  const seo = data.sanityProfileSettings.seo
  return (
    <>
      <Layout>
        <Seo
          title={seo?.title_tag}
          description={seo?.meta_description}
        />
        {data.file.childMarkdownRemark.frontmatter.sections.map((section, i) => {
          if (section.type === 'hero') {
            return (
              <Hero
                heading={section.heading}
                buttonText={section.text}
                buttonLink={section.link}
                backgroundImage={section.background_image}
                body={section.body}
              />
            )
          }
          if (section.type === 'about') {
            return (
              <>
                <Intro
                  heading={section.heading}
                  body={section.body}
                  image={section.image}
                />
              </>

            )
          }
          if (section.type === 'masonry') {
            return (
              <Masonry
              />
            )
          }

          if (section.type === 'homeform') {
            return (
              <HomeForm
                heading={section.heading}
                body={section.body}
                backgroundImage={section.background_image}
              />
            )
          }
          else {
            return null;
          }
        })}
      </Layout>
    </>
  )
}

export const query = graphql`
{
  file(sourceInstanceName: {eq: "main"}) {
    childMarkdownRemark {
      frontmatter {
        sections {
          body
          heading
          link
          text
          type
          background_image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 100)
            }
          }
          awards {
            description
            heading
            icon
          }
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 50)
            }
          }
        }
      }
    }
  }
  sanityProfileSettings {
    seo {
      meta_description
      title_tag
    }
  }
}

`