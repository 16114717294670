import * as React from "react"
import showdown from "showdown"
import { Link } from "gatsby"

export default function Intro({ heading, body, image }) {
    const converter = new showdown.Converter()
    return (
        <>
            <div className="py-20">
                <div className="container">
                    <div className="md:flex flex-none gap-10">
                        <div className="md:full w-full">
                            <div className="flex items-center">
                                <div className="text-center">
                                    <h2 className="h2 font-bold">{heading}</h2>
                                    <h3 className="text-2xl font-medium mb-10">Certified Business Broker</h3>
                                    <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(body) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-10">
                        <div className="mt-20 grid lg:grid-cols-5 gap-2 grid-cols-1 text-center items-center home-blocks justify-center">
                            <Link to="/about/">
                                <div className="border p-6">
                                    <p>About</p>
                                </div>
                            </Link>
                            <Link to="/succession-planning/">
                                <div className="border p-6">
                                    Succession Planning
                                </div>
                            </Link>
                            <Link to="/why-us/">
                                <div className="border p-6">
                                    Why us
                                </div>
                            </Link>
                            <Link to="/listings/">
                                <div className="border p-6">
                                    Listings
                                </div>
                            </Link>
                            <Link to="/financing/">
                                <div className="border p-6">
                                    Financing
                                </div>
                            </Link>
                            <Link to="/selling-a-practice/">
                                <div className="border p-6">
                                    Selling
                                </div>
                            </Link>
                            <Link to="/buying-a-practice/">
                                <div className="border p-6">
                                    Buying
                                </div>
                            </Link>
                            <Link to="/valuation/">
                                <div className="border p-6">
                                    Valuation
                                </div>
                            </Link>
                            <Link to="/testimonials/">
                                <div className="border p-6">
                                    Testimonials
                                </div>
                            </Link>
                            <Link to="/benefits/">
                                <div className="border p-6">
                                    Benefits
                                </div>
                            </Link>
                            <Link to="/contact/">
                                <div className="border p-6">
                                    Contact
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}