import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PrimaryButton from "../ui/PrimaryButton"
import { Link } from "gatsby"

export default function Hero({ _key, backgroundImage, heading, body, buttonLink, buttonText }) {
    return (
        <div key={_key} className="heroWrapper">
            <GatsbyImage image={backgroundImage.childImageSharp.gatsbyImageData} className="object-cover h-96 lg:h-full w-full" loading="eager" alt="Hero Image" />
            <div className="heroContent text-white text-center p-4">
                <h1 className="text-3xl lg:text-5xl font-thin mb-5">
                    <span className="font-bold">Experience</span> Does Count
                </h1>
                <p className="text-xl font-medium">{body}</p>
                <div className="mt-10">
                    <Link to="/listings/" className="primary-button md:py-4 mx-2 md:text-md text-xs">Explore Listings</Link>
                    <Link to="/contact/" className="primary-button md:py-4 bg-transparent md:text-md mx-2 text-xs border">Contact</Link>
                </div>
            </div>
        </div>
    )
}