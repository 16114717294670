import * as React from "react"
import PrimaryButton from "../ui/PrimaryButton"
import Form from "../templates/Form"

export default function HomeForm({ heading, body, backgroundImage, altTag, link, label }) {
    return (
        <>
            <div className="w-full home-form flex-items-center">
                <div className="md:p-20 p-5">
                    <div className="lg:w-3/5">
                        <div className="bg-white md:w-3/5 w-full md:p-18 p-10">
                            <h2 className="h2">{heading}</h2>
                            <p>{body}</p>
                            <div className="my-10">
                                <Form 
                                    source="https://www.cpabuysell.com"
                                    formName="Website Inquiry"
                                    subject="Website Contact Form"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}