import * as React from "react"
import showdown from "showdown"

export default function Blocks({ _key, awards, heading, icons }) {
    const converter = new showdown.Converter();
    return (
        <>
            <div key={_key} className="pb-20">
                <div className="container">
                    <h2 className="h2 text-center">{heading}</h2>
                    <div className="mt-20 grid md:grid-cols-4 grid-cols-2 gap-10">
                        {awards ?
                            awards.map((award, i) => {
                                return (
                                    <div key={i} className="flex flex-col">
                                        <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(award.icon) }} className="w-1/4 svg"/>
                                        <div className="mt-8">
                                            <h3 className="font-bold text-xl my-4">{award.heading}</h3>
                                            <span className="text-md">{award.description}</span>
                                        </div>
                                    </div>
                                )
                            })
                            : null}
                    </div>
                </div>
            </div>
        </>
    )
}