import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"

export default function Masonry({ bigImage, bigLink, bigText, firstImage, secondImage, thirdImage, fourthImage, firstLink, thirdLink, fourthLink, secondLink, firstText, secondText, thirdText, fourthText }) {
    return (
        <>
            <div>
                <div>
                    <div className="md:flex flex-none gap-2">
                        <div className="lg:w-1/2 relative">
                            <div className="md:h-[650px] h-64">
                            <Link to={bigLink}>
                                <GatsbyImage
                                    image={bigImage.childImageSharp.gatsbyImageData}
                                    className="object-cover w-full h-full"

                                />
                                <div className="absolute z-50 bottom-0 text-white p-10 text-2xl font-medium">
                                    <h2>{bigText}</h2>
                                </div>
                            </Link>
                            </div>
                        </div>
                        <div className="lg:w-1/2">
                            <div className="grid md:grid-cols-2 gap-2">
                                <div className="relative">
                                    <Link to={firstLink}>
                                        <div>
                                            <GatsbyImage
                                                image={firstImage.childImageSharp.gatsbyImageData}
                                                className="object-cover w-full h-80"
                                            />
                                            <div className="absolute z-50 bottom-0 text-white p-10 text-2xl font-medium">
                                                <h2>{firstText}</h2>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="relative">
                                    <Link to={secondLink}>
                                        <GatsbyImage
                                            image={secondImage.childImageSharp.gatsbyImageData}
                                            className="object-cover w-full h-80"

                                        />
                                        <div className="absolute z-50 bottom-0 text-white p-10 text-2xl font-medium">
                                            <h2>{secondText}</h2>
                                        </div>
                                    </Link>
                                </div>
                                <div className="relative">
                                    <Link to={thirdLink}>
                                        <GatsbyImage
                                            image={thirdImage.childImageSharp.gatsbyImageData}
                                            className="object-cover w-full h-80"

                                        />
                                        <div className="absolute z-50 bottom-0 text-white p-10 text-2xl font-medium">
                                            <h2>{thirdText}</h2>
                                        </div>
                                    </Link>
                                </div>
                                <div className="relative">
                                    <Link to={fourthLink}>
                                        <GatsbyImage
                                            image={fourthImage.childImageSharp.gatsbyImageData}
                                            className="object-cover w-full h-80"

                                        />
                                        <div className="absolute z-50 bottom-0 text-white p-10 text-2xl font-medium">
                                            <h2>{fourthText}</h2>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}